import React from 'react'
import { useState,useEffect} from 'react';
import { Amplify, Auth, API, Storage,Cache } from 'aws-amplify';
import {Flex, View, Button,Text,
    Divider,Radio,SelectField,TextField,Icon, Link} from '@aws-amplify/ui-react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import GeneralFooter from '../ui-components/GeneralFooter';
import  Inventory  from '../ui-components/Inventory';


Amplify.configure(awsconfig);

function InventoryContainer() {
    const [tableData,setTableData]= useState([]);

    useEffect(() => {
        downloadData();
    },[]);

    async function downloadData(){
        try{
            const market = Cache.getItem('marketCode');

            let mfnURL = market + "_Inventory/MFN/ASIN_latest_data.json";
            const mfnResult = await Storage.vault.get(mfnURL, { download: true });
            const mfnInventoryData = await new Response(mfnResult.Body).json(); 
            
            let afnURL = market + "_Inventory/AFN/ASIN_latest_data.json";
            const afnResult = await Storage.vault.get(afnURL, { download: true });
            const afnInventoryData = await new Response(afnResult.Body).json(); 
            
            const mfnData = Object.values(mfnInventoryData).map(item => ({
                asin: item.asin,
                sku: item.sellerSku,
                availableQuantity: item.totalQuantity,
            }));
            
            const afnData = Object.values(afnInventoryData).map(item => ({
                asin: item.asin,
                sku: item.sellerSku,
                availableQuantity: item.totalQuantity,
            }));
            
            const combinedDataMap = {};
            
            // Combine the MFN and AFN data
            [mfnData, afnData].forEach(dataSet => {
                dataSet.forEach(item => {
                    if (combinedDataMap[item.asin]) {
                       
                        combinedDataMap[item.asin].availableQuantity += item.availableQuantity;
                        combinedDataMap[item.asin].sku += `, ${item.sku}`;
                    } else {
                        combinedDataMap[item.asin] = { ...item };
                    }
                });
            });
            
            const combinedData = Object.values(combinedDataMap).filter(item => item.availableQuantity > 0);
                
            setTableData(combinedData);
            
        }
        catch (exception) {
            console.log(exception);
        }
    }
   
    return (
      <div  style={{ width: "100%" }}>
        <Flex
            paddingTop={'20px'}
            paddingLeft={'30px'}
            paddingRight={'30px'}
            overflow={'auto'}>
            <Flex
            gap="32px"
            direction="column"
            width="100%"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            position="relative"
            
            >
            <Flex
                gap="8px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                
            >
                <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="500"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Inventory"
                
                ></Text>
            </Flex>
           
                <Inventory  
                    data={tableData}
                    itemsPerPage={10}
                    overrides={{
                        "Inventory": { backgroundColor: "#f0f0f0" }, 
                        "label100616852": { fontWeight: "bold" },
                    }}
                />
            </Flex>
        </Flex>
        <GeneralFooter />
      </div>  
    );
}

export default withAuthenticator(InventoryContainer);